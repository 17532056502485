
@font-face {
  font-family: "Roboto";
  src: url('/public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url('/public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #fff;
}


h1 {
  color: #0099ff;
}

.container {
  margin: auto;
  max-width: 444px;
  margin-top: 48px;
}

.container img {
  display: block;
  width: 64px;
  margin: auto;
}

.form {
  /* max-width: 330px; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  margin-top: 30px;
}

.form .form__custom-button {
  margin-top: 50px;
}