.layout {
  display: flex;
  justify-content: center;
  padding: 8px;
}

.layout-row {
  flex-direction: row;
}

.layout-row .video {
  width: 600px;
  flex: 1;
}

.layout-row .video iframe {
  height: 520px;
  width: 100%;
}

.layout-row .terminal {
  flex: 0 0 700px;
}

.layout-column {
  flex-direction: column;
}

.layout-column .video {
  display: flex;
  justify-content: center;
}

.layout-column .video iframe {
  display: block;
  height: 320px;
  width: 480px;
}
