.deduction {
    background-color: #4caf50;
    color: #fff;
}

.deduction:hover {
  /* &:hover: { */
    background-color: #4caf50;
  /* } */
}

.deduction2 {
  background-color: #999;
}

.marks {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

mark {
  font-size: 13px;
  margin: auto;
}

.marks > * {
  /* margin: theme.spacing(1), */
}

.wellDone {
  font-size: 11px;
  background-color: #4caf50;
}

.notBad {
  font-size: 11px;
  background-color: #ff9800;
}

.inaccurate {
  font-size: 11px;
  background-color: #f44336;
}

/* root: {
  flexGrow: 1,
  position: "relative",
},
mark: {
  fontSize: 13,
  margin: "auto",
},
marks: {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& > *": {
    margin: theme.spacing(1),
  },
},

menuButton: {
  marginRight: theme.spacing(2),
},
title: {
  flexGrow: 1,
},
list: {
  width: 250,
},
fullList: {
  width: "auto",
}, */

/* 
avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  deduction: {
    backgroundColor: "#4caf50",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4caf50",
    },
  },
  deduction2: {
    backgroundColor: "#999",
    "&:hover": {
      backgroundColor: "#999",
    },
  }, */